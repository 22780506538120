
import Tabs from '@/components/atom/Tabs.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Home',
  components: {
    Tabs
  },
  setup() {
    const tabs = [
      { routerName: 'ActiveUsers', routerText: 'All users' },
      { routerName: 'ArchiveUsers', routerText: 'Archive' }
    ]
    // store.dispatch(UserActionTypes.GET_USERS)
    return { tabs }
  }
})
