<template>
  <div class="subnav">
    <h2>Users</h2>
    <Tabs :tabs="tabs" />
  </div>
  <div class="subroute">
    <router-view></router-view>
  </div>
</template>
<script lang="ts">
import Tabs from '@/components/atom/Tabs.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Home',
  components: {
    Tabs
  },
  setup() {
    const tabs = [
      { routerName: 'ActiveUsers', routerText: 'All users' },
      { routerName: 'ArchiveUsers', routerText: 'Archive' }
    ]
    // store.dispatch(UserActionTypes.GET_USERS)
    return { tabs }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.subnav
  background: #fff
  padding: 0 3.75rem
h2
  margin: 1rem 0
@media( max-width: $md-breakpoint)
  padding: 0
  h2
    display: none
</style>
